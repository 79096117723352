<template>
  <a-modal v-model="rechargeVisible" title="充值记录" @onCancel="handleCancel" width="1100px">
    <div class="modelDiv">
      <el-table :data="list" border style="width: 100%">
        <el-table-column prop="createTime" label="入账时间"> </el-table-column>
        <el-table-column prop="company" label="公司名称"> </el-table-column>
        <el-table-column prop="amount" label="入账金额">
          <template slot-scope="{row}">
            <div>{{ numFormat(row.amount || 0, 0) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="sts" label="入账状态">
          <template slot-scope="{row}">
            <div>{{ row.sts ? '已入账' : '未入账' }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="收款方式">
          <template slot-scope="{row}">
            <div>{{ row.type ? '后付费' : '预付费' }}</div>
          </template>
        </el-table-column>
      </el-table>
      <m-empty v-if="list.length === 0" style="height: 30vh; width: 100%">
        <!-- <template #description>暂无数据！</template> -->
      </m-empty>
      <!-- <a-pagination
        class="pagination"
        v-if="list.length > 0"
        show-size-changer
        :current="query.page"
        :total="total"
        :show-total="(total) => `共 ${total} 条`"
        @change="handleChangePage"
        @showSizeChange="onShowSizeChange"
        :pageSize="query.limit"
      /> -->
    </div>
  </a-modal>
</template>

<script>
import {
  fundDetails
} from '@/api/reportdatas'
import { numFormat } from '@/utils/dealNumber'
export default {
  data () {
    return {
      query: {
        page: 1,
        limit: 10
      },
      total: 0,
      list: []
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  watch: {
  },
  created () {
    this.fundDetails()
  },
  computed: {
    rechargeVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    numFormat,
    async fundDetails () {
      const { data = {} } = await fundDetails()
      this.list = data.item
      this.total = data.total
    },
    onShowSizeChange () {},
    handleChangePage () {},

    handleCancel () {
      this.query = {}
      this.$emit('handleCancel')
      this.rechargeVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
.modelDiv {
  height: 400px;
  overflow-y: auto;
  padding: 5px 20px;
}
.pagination{
  padding: 10px 0;
}
</style>
